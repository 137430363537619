import React from 'react';
import { graphql } from 'gatsby';
import PortableText from '@sanity/block-content-to-react';

import styled from 'styled-components';
import Newsletter from '../components/blocks/Newsletter';
import CoverArtist from '../components/covers/CoverArtist';
import YouTubeEmbed from '../components/YoutubeEmbed';
import PageTransition from '../components/PageTransition';

export default function SingleArtist({ data }) {
  const ButtonHolder = styled.div`
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      grid-column: 2 / span 10;
    }

    grid-column: 3 / span 8;
  `;
  const ArtistTextHolder = styled.div`
    @media screen and (max-width: 39.9375em) {
      grid-column: 1 / span 12;
    }

    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      grid-column: 2 / span 10;
    }

    grid-column: 3 / span 8;
  `;

  return (
    <PageTransition>
      <div className="wrapper background">
        <CoverArtist artist={data?.artist} />
        <title>{data?.artist.artistTitle} | Rizoom.net</title>
        <div className="block">
          <ArtistTextHolder>
            {data?.artist?._rawRichText?.map((block, i) => (
              <React.Fragment key={i}>
                {block._type === 'youtube' ? (
                  <YouTubeEmbed youtube={block.url} />
                ) : (
                  <>
                    <PortableText blocks={block} />
                  </>
                )}
              </React.Fragment>
            ))}
          </ArtistTextHolder>
          <ButtonHolder>
            {data.artist.websiteLink ? (
              <a
                href={data.artist.websiteLink}
                className="button"
                target="_blank"
              >
                Bezoek website
              </a>
            ) : null}
          </ButtonHolder>
        </div>
        <Newsletter title="Blijf up to date met Rizoom makers, verhalen en optredens" />
      </div>
    </PageTransition>
  );
}

export const query = graphql`
  query($slug: String!) {
    artist: sanityArtist(slug: { current: { eq: $slug } }) {
      name
      artistTitle
      artistIntro
      websiteLink
      discipline {
        name
      }
      _rawRichText(resolveReferences: { maxDepth: 10 })
      image {
        asset {
          fluid(maxWidth: 2200) {
            ...GatsbySanityImageFluid
          }
        }
      }
      slug {
        current
      }
    }
    footer: allSanitySiteSettings {
      edges {
        node {
          address_1
          address_2
          address_3
          facebook_url
          email
          instagram_url
          phone
          studio_title
          title
          title_1
          title_2
          vimeo_url
        }
      }
    }
  }
`;

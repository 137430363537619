import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const ImageStyles = styled.div`
  grid-column: 7 / span 6;
  .projectImage {
    @media screen and (max-width: 39.9375em) {
      min-height: 300px;
      height: auto;
    }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      height: 500px;
    }
    min-height: 700px;
    height: auto;
    overflow: auto;
    width: 50vw;
    img,
    picture source,
    picture img,
    picture {
      object-fit: contain;
      object-position: top;
    }
  }
`;

const CoverText = styled.div`
  grid-column: 1 / span 5;
  @media screen and (max-width: 39.9375em) {
    height: 300px;
  }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    min-height: 500px;
    height: auto;
  }
  min-height: 700px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .grey {
    margin-top: 10px;
    display: block;
  }
`;

export default function CoverArtist({ artist }) {
  return (
    <>
      <div className="block no-padding">
        <CoverText>
          <div onClick={() => history.back()}>
            <svg
              alt="back"
              width="35"
              className="arrow-back"
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              viewBox="0 0 25.988 25.483"
            >
              <g transform="translate(1 0.897)">
                <g
                  data-name="Icon feather-arrow-up-right"
                  transform="translate(11.869) rotate(45)"
                >
                  <path
                    data-name="Path 10"
                    d="M0,16.64,16.569,0"
                    transform="translate(0 0.145)"
                    fill="none"
                    stroke="#f45e3f"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    data-name="Path 11"
                    d="M0,.391,16.359,0l-.383,16.407"
                    transform="translate(0.145 0)"
                    fill="none"
                    stroke="#f45e3f"
                    strokeWidth="2"
                  />
                </g>
              </g>
            </svg>
          </div>
          <h3>{artist.artistTitle}</h3>
          <p className="lead">{artist.artistIntro}</p>
          <p>
            Diciplines
            <br />
            <span className="grey">
              {artist?.discipline?.length
                ? artist.discipline.map((x, i) => (
                    <React.Fragment key={i}>
                      {x.name}
                      <br />
                    </React.Fragment>
                  ))
                : null}
            </span>
          </p>
        </CoverText>
        <ImageStyles>
          {artist?.image?.asset ? (
            <Img fluid={artist.image.asset.fluid} className="projectImage" />
          ) : (
            <img src="https://i.pravatar.cc/300" />
          )}
        </ImageStyles>
      </div>
    </>
  );
}
